import "./App.css";
import "./assets/css/vendor/bootstrap/bootstrap.css"
import "./assets/css/main.css"

import { HomePage } from "./pages/Home/Home";

function App() {
  return (
    <HomePage/>
  );
}

export default App;
